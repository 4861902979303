import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class HS extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="hs">New Trier Township High School</b>
                        <br/>
                        Winnetka, IL
                        <br/>
                        August 2014 - May 2018
                    </Col>
                    <Col>
                    National Merit Finalist, Scholar Athlete, Departmental Award for Excellence in Mathematics, American Association of Physics Teachers Award
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default HS;