import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import './App.css';
import ScrollTable from './components/ScrollTable/index';
import './styles/global.scss';

class App extends Component {
  render () {
  return (
  	<Container className='app' fluid={true}>
	    <ScrollTable/>
	</Container>
  );
}
};

export default App;
