import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class StudyAbroad extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="beijing">Beijing Language and Culture University</b>
                        <br/>
                        Beijing, China
                        <br/>
                        June 2019 - August 2019
                    </Col>
                    <Col>
                        CHNSE 140A & 140B: 9 week summer study abroad coursework. Further developed ability to use Chinese at a more advanced level. Engaged in in-depth readings and discussions of various genres and writing styles, including argumentative essays, narratives, journalistic articles, and descriptive and literary writing. Studied reading and writing to specific audiences, and the use of complex structures and advanced vocabulary in formal speech and writing. 1 week social study research project in Inner Mongolia on cultural loss, modernization, and commercialization.
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default StudyAbroad;