import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import CollegeEd from '../CollegeEd/index';
import StudyAbroad from '../StudyAbroad/index';
import HS from "../HS/index";
import './styles.scss';

class Edu extends Component {
    render = () => {
        return (
            <Container className="edu">
                <h1 id="eduTitle">Education</h1>
                <hr className='line'/>
                <CollegeEd />
                <br/>
                <StudyAbroad />
                <br/>
                <HS/>
            </Container>
        );
    }
}

export default Edu;