import React, {Component} from 'react';
import {Row} from 'react-bootstrap';
import './styles.scss';

class HomeHero extends Component {
    render = () => {
        return (
			<main>
				<div className='homeHero'>
					<Row className='homeTitle'>
						Hi! I'm Amia Ross.
					</Row>
					<Row>
						<a id='link' className="aboutButton" href="#profile">about me</a>
						<a id='link' className="resumeButton" href="#edu">my experience</a>
						<a id='link' className="connectButton" href="#contact">connect with me</a>
					</Row>
				</div>
			</main>
        );
    }
}

export default HomeHero;