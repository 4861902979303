import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap'
import Img from 'react-image';
import homelogo from '../../assets/amialogo3.png';
import Edu from '../../components/Edu/index';
import Exp from '../../components/Exp/index';
import Connect from '../../components/Connect/index';
import Home from '../../components/Home/index';
import About from '../../components/About/index';
import Skills from '../../components/Skill/index';
import NewInterests from '../../components/NewInt/index';
import Projects from '../../components/Proj/index';
import './styles.scss';

class ScrollBar extends Component {
    render = () => {
        return (
            <Row>
                <Col xs={2} className="sticky-top">
                    <nav className="section-nav">
                        <ol>
                            <li><a className="homeNav" href="#home"><Img className='homelogo' src={homelogo} alt='Amia'/></a></li>
                            <li><a href="#profile">Profile</a></li>
                            <li><a href="#edu">Education</a></li>
                            <li><a href="#work">Experience</a></li>
                            <li><a href="#projects">Research & Projects</a></li>
                            {/* <li><a href="#leadership">Leadership & Activities</a></li> */}
                            <li><a href="#skills">Skills</a></li>
                            <li><a href="#interests">Interests</a></li>
                            <li><a href="#contact">Contact</a></li>
                        </ol>
                    </nav>
                </Col>
                <Col className="bodycol">
                <div className="body">
                    <section id="home">
                        <Home/>
                    </section>
                    <section id="profile">
                        <About/>
                    </section>
                    <section id="edu">
                        <Edu/>
                    </section>
                    <section id="work">
                        <Exp/>
                    </section>
                    <section id="projects">
                        <Projects/>
                    </section>
                    {/* <section id="leadership">
                        <Lead/>
                    </section> */}
                    <section id="skills">
                        <Skills/>
                    </section>
                    <section id="interests">
                        <NewInterests/>
                    </section>
                    <section id="contact">
                        <Connect/>
                    </section>
                </div>
                </Col>
            </Row>
        );
    }
}

window.addEventListener('DOMContentLoaded', () => {

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const id = entry.target.getAttribute('id');
            if (entry.intersectionRatio > 0) {
                document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.add('active');
            } else {
                document.querySelector(`nav li a[href="#${id}"]`).parentElement.classList.remove('active');
            }
        });
    });

    // Track all sections that have an `id` applied
    document.querySelectorAll('section[id]').forEach((section) => {
        observer.observe(section);
    });
    
});


export default ScrollBar;