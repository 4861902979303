import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import Img from 'react-image';
import Pic from '../../assets/iacconf.png';
import './styles.scss';

class Conf extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <Img className='reppic' src={Pic} alt='Conference Logo'/>
                    </Col>
                    <Col>
                        <b id='title'>International Astronautical Congress 2022, Oral Presentation</b>
                        <br/>
                        <p>I presented a first-authored paper "Quantifying the Available Solar Power near the Lunar South Pole" at the world's premier global space event in Paris in September 2022.</p>
                        <a id="resLink" href={require("../../assets/iac1.mp4")} title="Watch presentation" target="_blank" rel="noopener noreferrer"><i>Watch the presentation here. </i> <svg width="1rem" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" class="svg-inline--fa fa-external-link-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg></a>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Conf;