import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class Tempus extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="resTitle">Tempus Labs, Inc.</b>
                        <br/>
                        Chicago, IL
                        <br/>
                        June 2020 - August 2020
                    </Col>
                    <Col>
                        <b>Biotechnology Research Analyst</b>
                        <br/>
                        <p>Working directly under the Chief Strategy Officer at Tempus, my internship team created a database of 260+ oncology-focused biotech company profiles, including financial information (IPO date, market cap, funding rounds, valuation, etc.), personnel (founders, executives, board of directors), and assets & pipeline (mechanism of action, target indication, subtypes/genes of emphasis, clinical trials). We researched 18 healthcare-focused VC firms to further build our list of companies. We also analyzed and visualized trends in the mechanisms of action, target indications, subtypes/genes of emphasis, and novelty of the 800+ programs we profiled. We presented our findings to the Chief Strategy Officer, Chief Science Officer, Chief Medical Officer, Senior Vice President of Data Science, and Director of Operations.</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Tempus;