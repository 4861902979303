import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import Accordion from "../Accordion/Accordion";
import './styles.scss';

class CollegeEd extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4} className='title'>
                        <b id="harvard">Harvard University</b>
                        <br/>
                        Cambridge, MA
                        <br/>
                        August 2018 - May 2023
                        <br/>
                        (2020 - 2021 gap year)
                        <br/>
                        <br/>
                        <div id="blue"/> Computer Science
                        <br/>
                        <div id="yellow"/> STEM
                        <br/>
                        <div id="red"/> Humanities
                    </Col>
                    <Col className='courses'>
                        <div id="conc">
                            <b>A.B. Candidate in Computer Science</b>
                        </div>
                        <div className="cs">
                        <Accordion
                        title="COMPSCI 179: Design of Useful and Usable Interactive Systems"
                        content="Studied and practiced a range of design principles, effective creativity-related practices, and techniques for rapidly creating and evaluating product prototypes. Ideated and designed music-sharing social media app."
                        />
                        <Accordion
                        title="COMPSCI 187: Computational Linguistics"
                        content="Studied structural/formal, statistical, and neural methods, for a range of natural-language interpretation tasks structured as classes, sequences, tree structures, and meaning representations."
                        />
                        <Accordion
                        title="COMPSCI 136: Economics and Computation"
                        content="Studied game theory, peer production, reputation and recommender systems, prediction markets, crowd sourcing, network influence and dynamics, auctions and mechanisms, privacy and security, matching and allocation problems, computational social choice and behavioral game theory."
                        />
                        <Accordion
                        title="COMPSCI 124: Data Structures and Algorithms"
                        content="Studied recurrence relations, graph algorithms, greedy algorithms, dynamic programming, 'divide and conquer' algorithms, hashing, linear programming, randomized algorithms, NP-completeness, and heuristics."
                        />
                        <Accordion
                        title="COMPSCI 121: Introduction to Theoretical Computer Science"
                        content="Studied finite, uniform, and randomized computation including universality and uncomputability, equivalent models of computation, restricted computational models, and probabilistic computation; and efficient algorithms, including run-time models, polynomial time reductions, NP and NP-completeness, and space bounded computation."
                        />
                        <Accordion
                        title="COMPSCI 51: Abstraction and Design in Computation"
                        content="Learned to program in a variety of paradigms including imperative, functional, modular, lazy, generic, and object-oriented using OCaml, as well as studying data types, complexity analysis, and semantics."
                        />
                        <Accordion
                        title="COMPSCI 50: Introduction to Computer Science"
                        content="Studied abstraction, algorithms, data structures, encapsulation, resource management, security, and software engineering using C, Python, SQL, HTML, CSS, and JavaScript."
                        />
                        </div>
                        <div className="stem">
                        <Accordion
                        title="ECON 1800: Economics of the City"
                        content="..."
                        /> 
                        <Accordion
                        title="PSY 1454: Neuroscience Fiction"
                        content="Used film and television to inspire reading of scientific studies on mind control, bionic minds, mind reading, memory replay and modification, cognitive enhancement, the singularity, transhumanism, and neural entanglement."
                        /> 
                        <Accordion
                        title="GENED 1104: Science and Cooking"
                        content="Studied food components, energy, temperature, heat, phase transitions, enzymatic reactions, fermentation, elasticity, diffusion, heat transfer, viscosity, emulsions, foams, and desserts."
                        /> 
                        <Accordion
                        title="NEURO 80: Neurobiology of Behavior"
                        content="Studied cells and signals that process and transmit information, and the ways in which neurons form circuits that change with experience including the neurobiology of perception, learning, memory, emotion, and neurologic disorders."
                        /> 
                        <Accordion
                        title="ECON 50: Using Big Data to Solve Economic and Social Problems"
                        content="Studied equality of opportunity, education, innovation and entrepreneurship, health care, climate change, and crime using basic methods in data science, including regression, causal inference, and machine learning."
                        /> 
                        <Accordion
                        title="ESE 160: Space Science and Engineering"
                        content="Explored challenges involved in designing spacecraft for observation of Earth and exploration of other planets. Studied basic atmospheric and planetary science, key principles of remote sensing, telemetry, orbital transfer theory, propulsion and launch system design, and thermal and power management. Collaborated with a team to design a cube satellite mission to Venus to investigate the temperature profile of the thermosphere."
                        />
                        <Accordion
                        title="STAT 110: Introduction to Probability"
                        content="Studied sample spaces and events, conditional probability, Bayes' Theorem; univariate distributions including density functions, expectation and variance, Normal, t, Binomial, Negative Binomial, Poisson, Beta, and Gamma distributions; multivariate distributions including joint and conditional distributions, independence, transformations, and Multivariate Normal; law of large numbers, central limit theorem; and Markov chains including transition probabilities, stationary distributions, and convergence."
                        />
                        <Accordion
                        title="APMTH 22A: Solving and Optimizing"
                        content="Utilized mathematical proofs and MATLAB to study matrices, vector spaces, bases and dimension, inner products, least squares problems, eigenvalues, eigenvectors, singular values, singular vectors, partial differentiation, gradient and Hessian, critical points, and Lagrange Multipliers."
                        />
                        <Accordion
                        title="MATH 22A: Vector Calculus & Linear Algebra I"
                        content="Utilized techniques of mathematical reasoning to study vectors, lines, planes, parameterization of curves and surfaces, partial derivatives, directional derivatives and the gradient, optimization and critical point analysis, including constrained optimization and the Method of Lagrange Multipliers, integration over curves, surfaces and solid regions using Cartesian, polar, cylindrical, and spherical coordinates, divergence and curl of vector fields, and the Green's, Stokes's, and Divergence Theorems."
                        />
                        </div>
                        <div className='other'>
                        <Accordion
                        title="HAA 96A: Transformations"
                        content="..."
                        /> 
                        <Accordion
                        title="GENED 1049: Moral Inquiry in the Novels of Tolstoy and Dostoevsky"
                        content="..."
                        /> 
                        <Accordion
                        title="MODMDEST 106: Comparative Politics and Government in the Middle East"
                        content="..."
                        />
                        <Accordion
                        title="PHIL 125: Beyond Dualism: Descartes and His Critics"
                        content="Studied the two sides of Cartesian dualism, mind and body, the notorious metaphysical problems it gives rise to and five 17th and 18th century attempts to push back against it in the figures of Princess Elisabeth of Bohemia, the Cambridge Platonists, Margaret Cavendish, Anne Conway, and Anton Wilhelm Amo."
                        /> 
                        <Accordion
                        title="GENED 1102: Making Change When Change is Hard"
                        content="Using psychology, political science, and economics, and focusing on case studies, studied the possibilities and limits of “nudging”; the role of emotions and cognition, including individual biases; informational cascades; group polarization; information-seeking and information-avoidance; polarization; and belief change. Case studies included climate change; gun rights; air pollution; LGBTQ rights; privacy and abortion; and conservatism, with reference to the U.S. Supreme Court."
                        /> 
                        <Accordion
                        title="PHIL 109: Early Chinese Ethics"
                        content="Studied ethical debates between Confucian, Mohist, Daoist, and Legalist philosophers in early China through classical texts such as the Analects of Confucius, Mengzi, Xunzi, Mozi, and Zhuangzi and important contemporary scholarship on these texts."
                        /> 
                        <Accordion
                        title="JEWISHST 152: Renaissance and Revolution: Judaism, Zionism & Israel"
                        content="Explored crucial issues and debates at play in the histories of the Zionism and the State of Israel, as they relate to Jewish identity, Zionism’s place in Jewish history and Israel’s identity as a democratic, yet highly conflicted, nation-state. Wrote a 30+ page research paper discussing themes from various Israeli war memoirs across four major military conflicts."
                        /> 
                        <Accordion
                        title="GENED 1049: East Asian Cinema"
                        content="Studied major works, genres, and waves of East Asian cinema from the silent era to the present, including films from China, Japan, Korea, Taiwan, and Hong Kong, as well as issues ranging from formal aesthetics to historical representation, from local film industries to transnational audience reception."
                        /> 
                        <Accordion
                        title="HIST 1017: Jews in the Modern World"
                        content="Studied Jewish history from the seventeenth century to the present in the Middle East and North Africa, Europe, and North America, with a focus on the impact of modernity on Jewish society, the transformation of Judaism and the formation of secular Jewish culture, continuity and rupture between older forms of antisemitism and the Holocaust, modern Jewish political movements, and the contemporary Jewish world’s two primary centers, the United States and Israel."
                        /> 
                        <Accordion
                        title="AFVS 107: Studies of the Built North American Environment since 1580"
                        content="Learned techniques to analyze and explain the built North American environment. Wrote two research papers, one detailing personally experienced changes in an elite American high school between the 1960s and 2010s, and one arguing that Superman is Jewish."
                        /> 
                        <Accordion
                        title="COMPLIT 166: Jews, Humor, and the Politics of Laughter"
                        content="Explored the concept of therapeutic joking, the politics of self-deprecation, and strategies of masking social critique behind a well-timed joke. Investigated literature, stand-up comedy, film, and television of the twentieth and twenty-first century in order to consider the theory, mechanics, and techniques of comedy and humor and determine how and when a text or performance gets labeled Jewish, by whom and for what purposes."
                        /> 
                        <Accordion
                        title="PHIL 173: Metaethics"
                        content="Closely examined of three metaethical views that take a deflationary approach toward the moral domain: nihilism, relativism, and expressivism."
                        /> 
                        <Accordion
                        title="LING 83: Language, Structure, and Cognition"
                        content="Studied the languages of the world; their distribution, recurrent structural properties, and genetic classification; processes of language contact; and the relationship between language and the brain."
                        /> 
                        <Accordion
                        title="PHIL 156: Philosophy of Mind"
                        content="Examined the relation between the mind and the natural world including the mind – body problem and proposed solutions to it, consciousness, and the mind’s representation of the world through influential papers from the latter half of the 20th Century."
                        /> 
                        <Accordion
                        title="CHNSE 130A & 130B: Pre-Advanced Modern Chinese"
                        content="Developed Chinese proficiency in both spoken and written language. Discussed texts based on current issues and cultural phenomena. Expanded vocabulary, mastered complex grammatical structures, and developed ability to perform tasks involving description, narration, and argumentation at the discourse level."
                        /> 
                        <Accordion
                        title="FRSEMR 30Q: Death and Immortality"
                        content="Discussed questions of death, life, and immortality from a variety of perspectives, ranging from those of philosophers such as Peter Singer and Descartes to scientific concepts such as cryonics. Class topics included brain death/irreversible loss of consciousness, defining death, death and meaning, immortality and personal identity, the desirability of immortality, and the collective afterlife."
                        /> 
                        </div>
                    </Col>
        </Row>
        </Container>
        );
    }
};

export default CollegeEd;