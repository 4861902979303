import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import Microsoft from '../Microsoft/index';
import Vianai from '../Vianai/index';
import Smith from '../Smith/index';
import CIERA from '../CIERA/index';
import HSS from '../HSS/index';
import Tempus from '../Tempus/index';
import Feast from '../Feast/index';
import LIT from '../LIT/index';
import Tarts from '../Tarts/index';
import Duolingo from '../Duolingo/index';
import './styles.scss';

class Experience extends Component {
	render = () => {
		return (
			<Container>
			<h1 id="workTitle">Experience</h1>
            <hr className='line'/>
			<Duolingo/>
			<br/>
			<Microsoft/>
			<br/>
			<Vianai/>
			<br/>
			<Feast/>
			<br/>
			<Tarts/>
			<br/>
			<Smith/>
			<br/>
			<LIT/>
			<br/>
			<HSS/>
			<br/>
			<Tempus/>
			<br/>
			<CIERA/>
			</Container>
		);
	}
}

export default Experience;