import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class Microsoft extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="resTitle">Microsoft - Identity Division</b>
                        <br/>
                        Redmond, MA
                        <br/>
                        May 2021 - July 2021
                    </Col>
                    <Col>
                        <b>Program Manager Intern</b>
                        <br/>
                        <p>Especially during and after the pandemic, remote work and collaboration increased significantly. As a result, more organizations were inviting guest employees from external sources to utilize their resources for collaboration. However, guest users - especially those that are stale or over-permissioned - can serve as potential security vectors into a host organization.</p>
                        <br/>
                        <p>Throughout the summer, I worked on a project to address this problem. I ideated 10+ analytics to surface information about this problem and tested them on customer data. Concurrently, I demoed in-progress analytics capabilities to 5 customers, utilizing their feedback to iterate and create new analytics. I worked with a UX designer to mock up a prototype of the product and translate it to product-quality wireframes, and managed a software engineer and intern to develop two scaled-down analytics for initial testing. I also collaborated with 5 other program managers to set the stage for incorporating different aspects of previous products with my summer project.</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Microsoft;