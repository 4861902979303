import React, {Component} from "react";
import {Container, Row, Col, ProgressBar} from 'react-bootstrap';
import Star from "../Star/star";
import Unstar from "../Star/unstar";
import "./styles.scss";

class Skills extends Component {
    render = () => {
      return (
        <Container className="skills">
          <h1 id="skillTitle">Skills</h1>
          <hr className='line'/>
          <div className="skill_section">
            <Row className="skill">
              <Col className="skill_title">
                <b>Technical</b>
              </Col>
              <Col className="skill_name">
                Python
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Star/><Star/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
              <Col/>
              <Col className="skill_name">
                Figma
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Star/><Star/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                Adobe XD
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Star/><Star/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                HTML
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Star/><Star/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                CSS
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Star/><Star/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                Java
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Star/><Unstar/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                OCaml
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Star/><Unstar/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                C
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Unstar/><Unstar/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                C++
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Unstar/><Unstar/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                React
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Unstar/><Unstar/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                Stata
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Unstar/><Unstar/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                Sketch
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Unstar/><Unstar/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                SQL
              </Col>
              <Col className="skill_stars">
                <Star/><Star/><Unstar/><Unstar/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                Flask
              </Col>
              <Col className="skill_stars">
                <Star/><Unstar/><Unstar/><Unstar/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                Javascript
              </Col>
              <Col className="skill_stars">
                <Star/><Unstar/><Unstar/><Unstar/><Unstar/>
              </Col>
            </Row>
            <Row className="skill">
            <Col/>
              <Col className="skill_name">
                Matlab
              </Col>
              <Col className="skill_stars">
                <Star/><Unstar/><Unstar/><Unstar/><Unstar/>
              </Col>
            </Row>
          </div>
          <br/>
          <div className="language_section">
          <Row className="lang">
              <Col className="langTitle">
                <b>Language</b>
              </Col>
                <Col className="langName">
                  English
                </Col>
            <Col className="langprog">
              <ProgressBar now={100} label={"Native"}/>
            </Col>
            </Row>
            <Row className="lang">
              <Col />
                <Col className="langName">
                  Chinese (Mandarin)
                </Col>
            <Col className="langprog">
              <ProgressBar animated now={75} label={"Advanced"}/>
            </Col>
            </Row>
            <Row className="lang">
              <Col />
                <Col className="langName">
                  Hebrew
                </Col>
            <Col className="langprog">
              <ProgressBar className="prog" animated now={75} label={"Advanced"}/>
            </Col>
            </Row>
            <Row className="lang">
              <Col />
                <Col className="langName">
                  Korean
                </Col>
            <Col className="langprog">
              <ProgressBar className="prog" animated now={25} label={"Beginner"}/>
            </Col>
            </Row>
            <Row className="lang">
              <Col />
                <Col className="langName">
                  French
                </Col>
            <Col className="langprog">
              <ProgressBar className="prog" animated now={25} label={"Beginner"}/>
            </Col>
            </Row>
          </div>
        </Container>
      );
    }
}

export default Skills;