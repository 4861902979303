import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class Duolingo extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="resTitle">Duolingo</b>
                        <br/>
                        Pittsburgh, PA
                        <br/>
                        May 2022 - August 2022
                    </Col>
                    <Col>
                        <b>Associate Product Manager Intern</b>
                        <br/>
                        <p>
                            Duolingo's language learning app employs a competitive leaderboard system, where every week learners have the opportunity to advance through 10 different leagues. While some learners invested lots of time trying to advance to the top leagues, others tended to stagnate in the lower leagues and engage less with the app overall.
                        </p>
                        <br/>
                        <p>Throughout the summer, I was tasked with developing a product roadmap to increase lower league learner engagement. I conducted UXR with 200+ users through a combination of a survey and live interviews to extract motivational aspects and pain points of the leaderboards feature. I also analyzed several experiments and key growth metrics in context of users in lower leaderboard leagues to determine how much impact addressing these learners could have. By the end of the summer, I had ideated 20+ new leaderboard features targeting aforementioned learners, collaborating with engineering and design to determine the investment necessary to develop a rudimentary prioritization scheme by ROI. I launched one of these features as an experiment, resulting in +20,000 daily active users, and put another one in the development pipeline. I presented my research findings and roadmap highlights to Chief Product Officer and Growth, Monetization, and Learning area leads.</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Duolingo;