import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class Vianai extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="resTitle">Vianai Systems</b>
                        <br/>
                        Tel Aviv, Israel
                        <br/>
                        January 2021 - April 2021
                    </Col>
                    <Col>
                        <b>Machine Learning and Data Science Intern</b>
                        <br/>
                        <p>At Vianai, I performed competitive analyses of 60+ AI technology companies in terms of feature offerings & industry verticals, choosing an additional 10+ for user experience focus. I also researched multi-criteria decision analysis, including its application areas and solution categories, types and methods, developing example demonstrations as well. On the technical side, I also cleaned and compiled phone and email communication data for a client company for the data scientists on my team to perform advanced inference. I presented all of my work throughout the internship to the Head of Platform and several principal data scientists and product managers.</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Vianai;