import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import AABweb from '../AABweb';
import Venus from '../Venus';
import Optilife from '../Optilife';
import Conf from '../Conf';
import LSIC from '../LSIC';
import Aux from '../Aux';
import Acta from '../Acta';
import './styles.scss';

class Proj extends Component {
    render = () => {
        return (
            <Container className="proj">
                <h1 id="projTitle">Research & Projects</h1>
                <hr className='line'/>
                <Acta/>
                <br/>
                <br/>
                <Conf/>
                <br/>
                <br/>
                <Aux/>
                <br/>
                <br/>
                <LSIC/>
                <br/>
                <br/>
                <Venus/>
                <br/>
                <br/>
                <AABweb/>
                <br/>
                <br/>
                <Optilife/>
            </Container>
        );
    }
}

export default Proj;