import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class Feast extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="resTitle">Clubfeast, Inc.</b>
                        <br/>
                        San Francisco, CA
                        <br/>
                        August 2020 - October 2020
                    </Col>
                    <Col>
                        <b>Associate Product Manager and Lead UI/UX Designer</b>
                        <br/>
                        <p>Clubfeast is a subscription-based restaurant delivery app in which customers subscribe weekly to a predetermined number of deliveries for a predetermined number of people, order meals at least one day in advance, and receive meals in a predetermined delivery window. Clubfeast delivers restaurant meals 40% cheaper than competitors and addresses inefficiency in restaurant kitchen and ingredient usage, food delivery, and customer expenses.</p>
                        <br/>
                        <p>As an associate product manager, my role entailed optimizing and improving elements of the customer interface, directing coders, and testing changes. As lead UI/UX designer, I used Adobe XD to wireframe and prototype the customer mobile app, and directed early-stage code development.</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Feast;