import React, {Component} from 'react';
import './styles.scss';

class Connect extends Component {
	render = () => {
		return (
			<div className='connect'>
				<h1 id="conTitle">Contact</h1>
                <hr className='line'/>
				<p className='name'>
					Feel free to reach out to me directly via email at <a id="email" href = "mailto: akr4500@gmail.com">akr4500@gmail.com</a>, or fill out the form below!
				</p>
				<iframe title="connect-form" src="https://docs.google.com/forms/d/e/1FAIpQLSees754ru7mcVM46ggY0JoBgilLl4GaXyR_5iodAMeLxszyBw/viewform?embedded=true" width="640" height="812" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
			</div>
		);
	}
}

export default Connect;