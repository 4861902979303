import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import Img from 'react-image';
import Pic from '../../assets/lsic.png';
import './styles.scss';

class LSIC extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <Img className='reppic' src={Pic} alt='Report Cover Page'/>
                    </Col>
                    <Col>
                        <b id='title'>Lunar Surface Innovation Consortium, Lightning Talk</b>
                        <br/>
                        <p>I gave a 1-minute lightning talk on my first-authored paper "Quantifying the Available Solar Power near the Lunar South Pole" at the LSIC's spring meeting in 2021.</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default LSIC;