import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class Tarts extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="resTitle">Three Tarts Bakery and Cafe</b>
                        <br/>
                        Northfield, IL
                        <br/>
                        August 2020 - December 2020
                    </Col>
                    <Col>
                        <b>Cake Decorator</b>
                        <br/>
                        <p>I decorated 100+ cupcakes and 50+ full size cakes every week to order.</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Tarts;