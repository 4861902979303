import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import './styles.scss';

class NewInt extends Component {
	render () {
		return (
			<Container className='intPage'>
                <h1 id="intTitle">Interests</h1>
                	<hr className='line'/>
					<br/>
					<Row className="interest">
                        I swear I'm interesting just give me a second
                    </Row>
			</Container>
		);
	}
};

export default NewInt;