import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class CIERA extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="resTitle">Northwestern University Center for Interdisciplinary Exploration and Research in Astrophysics</b>
                        <br/>
                        Evanston, IL
                        <br/>
                        June 2017 - August 2017
                    </Col>
                    <Col>
                        <b>Research Intern</b>
                        <br/>
                        <p>I worked under the guidance of a graduate student, Katie Breivik, and Professor Vicky Kalogera. My task was to create a database of neutron star and black hole binaries from different binary evolution models; the database was to be used to investigate how different models influence the number and properties of these binaries observable by LIGO. Under their instruction, I used a computer program called COSMIC (Compact Object Synthesis and Monte-Carlo Investigation Code), which in turn utilized BSE (Binary Star Evolution code), to simulate binary star evolutions and record the occurrences of three outcomes (neutron star-neutron star binaries, neutron star-black hole binaries, and black hole-black hole binaries). </p>
                        <a id="resLink" href={require("../../assets/docs/Research Portfolio CIERA.pdf")} title="Open research portfolio" target="_blank" rel="noopener noreferrer"><i>Read more here. </i> <svg width="1rem" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="external-link-alt" class="svg-inline--fa fa-external-link-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"></path></svg></a> 
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default CIERA;