import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class LIT extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="resTitle">HGSE Learning, Innovation and Technology Lab</b>
                        <br/>
                        Cambridge, MA
                        <br/>
                        August 2020
                    </Col>
                    <Col>
                        <b>Computer Science Research Assistant</b>
                        <br/>
                        <p>My team at the LIT lab created a multimodal learning analysis website for a Fall 2020 graduate course to replace the usual modal sensors students would receive during in-person learning. Specifically, I used HTML, CSS, and Javascript to integrate an eye-tracking modality into the website and standardize the modality to conform with the other tools.</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default LIT;