import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class HSS extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="resTitle">Harvard Summer School</b>
                        <br/>
                        Cambridge, MA
                        <br/>
                        June 2020 - August 2020
                    </Col>
                    <Col>
                        <b>Teaching Fellow</b>
                        <br/>
                        <p>I was on the teaching staff for <i>Introduction to Computer Science with Python</i>. I taught twice-weekly sections to 10-20 students regarding computer programming fundamentals, Python basics, and problem-solving techniques. In addition to sections, I hosted twice-weekly office hours to further explain and walk students through specific conceptual and homework-related questions to students. I also graded hundreds of tests and problem set submissions on a weekly basis. </p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default HSS;