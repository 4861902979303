import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import './styles.scss';

class Smith extends Component {
    render = () => {
        return (
            <Container>
                <Row>
                    <Col xs={4}>
                        <b id="title">Harvard-Smithsonian Center for Astrophysics</b>
                        <br/>
                        Cambridge, MA
                        <br/>
                        May 2020 - Present
                    </Col>
                    <Col>
                        <b>Computer Science Research Intern</b>
                        <br/>
                        <p>I worked under Dr. Martin Elvis to determine the potential heights and power generation capabilities of solar power towers built on the Moon. This project built off of an earlier paper by Dr. Elvis, which discusses the lunar south pole's Peaks of Eternal Light (very small areas with virtually constant solar illumination and thus solar power) and a valuable resource (water) in a permanently dark crater. However, because building high increases the amount of continuous illuminated area, building tall towers on the lunar south pole is of interest for resource sharing and cooperation between countries in the near future of space exploration. Thus, we aimed to calculate within reasonable assumptions how high a solar power generation tower could be built on the lunar south pole, as well as how much power such towers could generate.</p>
                        <p>My role specifically entailed utilizing Python to map solar illumination of lunar south pole at various heights above the surface, graph illuminated area at various illumination intervals, analyze trends of solar illumination through monthly oscillating angles of illumination, determine estimated power generation capabilities from the towers. A first-authored paper titled "Quantifying the Available Solar Power near the Lunar South Pole" is in preparation.</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Smith;